.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: linear-gradient(180deg, #E7E6E7 0%, rgba(255, 255, 255, 0) 100%), #D8D8D8;
  border: 1px solid #939393;
  padding: 0.375rem 1.6875rem
}

.search_box {
  display: flex;
  align-items: center;
}

.search_input {
  width: 16.625rem;
  height: 1.875rem;
  background: #FFFFFF;
  border: 1px solid #000000;
  padding: 0 0.375rem;
}

.search_button {
  height: 1.875rem;
  padding: 0 0.4375rem;
  margin-left: 0.875rem;
  background: #C4C4C4;
  border: 1px solid #000000;
}

.search_button:hover {
  cursor: pointer;
}

.controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  padding: 0.4375rem;
  border-radius: 10px;
  transition: 0.2s;
}

.button_box span {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #000000;
}

.button_box:hover {
  cursor: pointer;
  box-shadow: 0 0 10px 1px rgba(34, 60, 80, 0.2) inset;
}

.disabled {
  opacity: 0.3;
}

.disabled:hover {
  cursor: default;
  box-shadow: none;
}
