.container {
  width: 100%;
  margin-top: 1rem;
}

.languages_box {
  padding: 1rem;
  border: 1px solid grey;
  height: 18em;
  overflow: scroll;
}

.languages_box > div {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0.375rem;
  border-bottom: 1px solid rgba(100, 100, 100, 0.1);
}

.languages_box > div:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.edit {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0 0.5rem;
  border-radius: 10px;
  background-color: rgba(130, 130, 130, 0.2);
  margin-left: 2rem;
  opacity: 0.3;
  transition: 0.2s;
}

.edit:hover {
  cursor: pointer;
  opacity: 1;
}

.default_flag {
  margin-left: 1rem;
  color: grey;
}

.controls {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.controls input {
  padding: 0.375rem 1rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
  margin-right: 1rem;
}

.controls input:focus {
  box-shadow: 0.1rem 0.1rem 0.35rem rgba(0, 0, 0, 0.3);
}

.controls > div {
  margin-top: 0.375rem;
}

.buttons_box {
  margin-top: 2rem !important;
}

.buttons_box > span {
  margin-left: 1rem;
}

.buttons_box > span:first-child {
  margin-left: 0;
}

.checkbox_box {
  display: flex;
  align-items: center;
}
