.container {
  display: flex;
  flex-direction: column;
  margin-top: 1.0625rem;
  padding-top: 1rem;
  border-top: 1px solid grey;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.185rem;
  color: #000000;
  user-select: text;
}

.title > span {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;

  opacity: 0.3;
  padding: 0 0.5rem;
  border-radius: 10px;
  background-color: rgba(130, 130, 130, 0.2);
  transition: 0.2s;
}

.title > span:hover {
  cursor: pointer;
  opacity: 1;
}

.tip {
  margin: 0.875rem 0 1.375rem 0;
  padding: 0.375rem;
  background: #FFFFE2;
  border: 1px solid #939393;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #000000;
}

.translation_line {
  display: grid;
  grid-template-columns: 2fr 22fr;
  align-items: center;
  margin-top: 0.875rem;
  content-visibility: auto;
}

.translation_line span {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #000000;
}

.translation_line > div {
  position: relative;
}

.translation_line > div > textarea {
  width: 100% !important;
  overflow: hidden;
  resize: none;
  padding: 0.375rem;
  background: #FFFFFF;
  border: 1px solid #939393;
  field-sizing: content;

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #000000;
  unicode-bidi: plaintext;
}
