.tip {
  color: #938F8F;
}

.input {
  width: 100%;
  margin-top: 1rem;
  padding: 0.375rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
}

.input:focus {
  box-shadow: 0.1rem 0.1rem 0.35rem rgba(0, 0, 0, 0.3);
}
