* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'sans-serif';
  user-select: none;
}

button {
  appearance: none;
  border-width: 0;
  background-color: transparent;
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.app {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: #ECECEC;
}

.main {
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 9fr;
  overflow: hidden;
}

.modal_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modal_container_error {
  z-index: 101;
}

.modal_container_confirm {
  z-index: 100;
}

.modal {
  position: relative;
  width: 37.5rem;
  padding: 1rem 2rem;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.json_compare_modal {
  width: 65rem;
}

.modal_delete {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  opacity: 0.4;
  transition: 0.2s;
}

.modal_delete:hover {
  opacity: 1;
  cursor: pointer;
}

.modal_title {
  font-weight: 700;
}

.modal_body {
  width: 100%;
  max-height: 60vh;
  margin-top: 1rem;
  text-align: center;
}

.scroll {
  overflow: scroll;
}

.json_compare_body {
  max-height: 60vh;
  overflow: scroll;
}

.json_compare_body span {
  display: block;
  text-align: left;
  padding: 0.2rem 0.5rem;
  white-space: pre-wrap;
}

.json_compare_modal_collapse_placeholder {
  text-align: center;
  background-color: rgba(196, 196, 196, 0.3);
  font-size: 2rem;
}

.modal_form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal_form input {
  width: 50%;
  padding: 0.375rem;
  margin-bottom: 1rem;
  user-select: auto;
}

.modal_form > input::placeholder {
  color: #938F8F;
}

.modal_controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
}

.button {
  padding: 0.375rem 1rem;
  margin-top: 2rem;
  border-radius: 10px;
  text-align: center;
  background-color: rgba(100, 149, 237, 0.5);
  color: white;
  transition: 0.3s;
}

.button:hover {
  cursor: pointer;
  background-color: rgba(100, 149, 237, 0.8);;
}

.button_disabled {
  background-color: #C4C4C4;
  color: black;
}

.button_disabled:hover {
  background-color: #C4C4C4;
  cursor: default;
}

button,
button:focus,
input,
input:focus {
  outline: none;
}
