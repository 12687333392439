.form_block {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form_block > input {
  margin-top: 0.5rem;
  width: 100% !important;
}

.controls {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}
