.form {
  display: flex;
  flex-direction: column;
  gap: 1rem
}

.form_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
}

.form_group span {
  font-size: 14px;
}

.form_group input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
}

.form_controls {
  width: max-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.form_controls__cancel,
.form_controls__submit {
  padding: 0.2rem 1rem;
  border-radius: 4px;
  font-size: 20px;
  color: #FFFFFF;
}

.form_controls__cancel {
  background: #f87171;
}

.form_controls__submit {
  position: relative;
  background: #a3e635;
}

.form_controls__submit:disabled {
  background: #c4c4c4;
}

.form_controls__submit:disabled:after {
  content: 'Loading...';
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(10px, -50%);
  font-size: 12px;
  color: #000000;
}
