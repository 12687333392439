.files_list {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  max-height: inherit;
  overflow-x: hidden;
  overflow-y: scroll;
}

.files_list > div {
  padding: 0.325rem;
  border-radius: 5px;
  transition: 0.2s;
}

.files_list > .active,
.files_list > div:hover {
  cursor: pointer;
  background-color: #C4C4C4;
}
