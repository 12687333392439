.container {
  display: flex;
  align-items: center;
  padding: 0.165rem 0.875rem 0.165rem;
}

.container > div {
  display: flex;
  align-items: center;
  width: 100%;
}

.container:hover {
  cursor: pointer;
}

.container span {
  margin-right: 0.5rem;
}

.container span:last-child {
  margin-right: 0;
}

.open_folder svg {
  transform: rotate(90deg);
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.185rem;
  color: #000000;
}

.red {
  color: crimson;
}

.orange {
  color: darkorange;
}

.green {
  color: forestgreen;
}

.hidden {
  opacity: 0.01;
}

.selected {
  background: #C4C4C4;
  width: 50vw;
}

.opened_branch,
.opened_branch > .opened_branch {
  position: relative;
  padding-left: 1.3rem;
}

.opened_branch::after {
  content: '';
  height: 100%;
  width: 1px;
  position: absolute;
  left: 1.2rem;
  top: 0;
  background-color: rgba(130, 130, 130, 0.4);
}
