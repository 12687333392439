.container {
  position: relative;
  padding: 0.875rem 2.0625rem 0.875rem 3.75rem;
  height: 100%;
  overflow: scroll;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 1.675rem;
  color: #000000;
}

.content > div:first-child {
  border-top: none !important;
}

.dropzoneWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.dropzone {
  position: fixed;
  height: calc(100% - 4.875rem);
  width: 75%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  text-align: center;
}

.dropzone > span {
  width: 100%;
  height: 100%;
  background-color: rgba(100, 149, 237, 0.3);
  border: 2px dashed #6495ED;
}
