.container {
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #939393;
  overflow: hidden;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 3.375rem;
  padding: 0.875rem 1rem;
  background: #DDDDDD;
  border: 1px solid #938F8F;
}

.save_button {
  margin-top: 0 !important;
}

.head_title {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5625rem;
  line-height: 1.675rem;
  color: #000000;
}

.folders_box {
  position: relative;
  height: calc(100% - 3.375rem);
  padding: 0.785rem 0 0 0;
  overflow: scroll;
}
