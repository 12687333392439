.container {
  width: 100%;
  padding: 0 4rem;
  max-height: 31.25rem;
  overflow: scroll;
}

.container,
.container > div {
  position: relative;
  margin-top: 1rem;
}

.container input {
  width: 100%;
  margin-top: 1rem;
  padding: 0.375rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
}

.container input:first-child {
  margin-top: 0;
}

.container input:focus {
  box-shadow: 0.1rem 0.1rem 0.35rem rgba(0, 0, 0, 0.3);
}

.container > div:last-child {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0;
}


.container > div > svg {
  transform: translate(30%, 15%);
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 1.5rem;
  opacity: 0.3;
  transition: 0.4s;
}

.container > div > svg > path {
  fill: #6495ED;
}

.container > div > svg:hover {
  cursor: pointer;
  opacity: 0.8;
}

.tip {
  color: #938F8F;
}
